// Importing necessary libraries and components
import React from 'react';
import Header from './Header';
import './App.css';

// Main App function
function App() {
  return (
    <div className='App' style={{ fontFamily: "'IBM Plex Mono', monospace" }}>
      <Header />
      <main style={{ flex: 1 }}>
        <h1>Hi, I'm Joe</h1>
        <p>I'm a data scientist and machine learning engineer with a love for technology.</p>
        <p>Outside of work, I find balance in nature through running, hiking and surfing.</p>
        <p>This website is a place to share my thoughts, projects and experiences.</p>
        <h2>Projects</h2>
        <ul>
          <li><a href="https://aussiequant.com" target="_blank" rel="noopener noreferrer" style={{ color: '#0073e6' }}>AussieQuant</a>- Alternative datasets for the ASX</li>
          <li><a href="https://github.com/josephtwilliams/FineTuneYou" target="_blank" rel="noopener noreferrer" style={{ color: '#0073e6' }}>FineTuneYou</a>- Finetune & RAG chatbot framework</li>
        </ul>
      </main>
    </div>
  );
}

// Exporting App component
export default App;
