import React from 'react';
import './Header.css';

function Header() {
  return (
    <div className='header'>
      <a className="header-link" href="https://www.linkedin.com/in/243joe" target="_blank" rel="noopener noreferrer">LinkedIn</a>
      <a className="header-link" href="https://github.com/josephtwilliams" target="_blank" rel="noopener noreferrer">GitHub</a>
      <a className="header-link" href="https://twitter.com/243joe" target="_blank" rel="noopener noreferrer">Twitter</a>
      <a className="header-link" href="https://josephtwilliams.github.io/" target="_blank" rel="noopener noreferrer">Blog</a>
    </div>
  );
}

export default Header;
